import { getYesterdaysPuzzle } from '../utils/promptFunctions';

export default function YesterdaySolution() {
  const solution = getYesterdaysPuzzle()?.solution;

  if (!solution) {
    return null;
  }

  return (
    <div className="mt-4 pt-4 pb-2 border-t">
      <p className="text-lg font-bold text-center mb-3 text-gray-800 dark:text-white">
        Yesterday's Solution
      </p>
      <div className="flex flex-col items-center">
        <p className="text-lg tracking-wide text-gray-700 dark:text-white font-heading uppercase">
          {solution[0]}
        </p>
        <p className="text-lg tracking-wide text-gray-700 dark:text-white font-heading uppercase">
          {solution[1]}
        </p>
        <p className="text-lg tracking-wide text-gray-700 dark:text-white font-heading uppercase">
          {solution[2]}
        </p>
      </div>
    </div>
  );
}
