import React, { useState, createContext, useEffect } from 'react';
import {
  loadRevealedLetterFromLocalStorage,
  loadSolutionFromLocalStorage,
} from '../utils/localStorage';
import { getTodaysPuzzle } from '../utils/promptFunctions';

export interface ContextState {
  solveState: string[];
  // example solution for letter reveal purposes, not necessarily the only acceptable solution
  solution: string[];
  activeWord: number;
  activeLetter: number;
  promptLetters: number[][];
  // indices of the revealed letter in words
  revealedLetters: number[][];
  // the letter itself
  revealedLetter: string;
  isSolved: boolean;
  setSolveState: (state: string[]) => void;
  setActiveWord: (word: number) => void;
  setActiveLetter: (letter: number) => void;
  setRevealedLetter: (letter: string) => void;
  setIsSolved: (isSolved: boolean) => void;
}

export const GameContext = createContext<ContextState>(null);

const { prompt: prompts, solution } = getTodaysPuzzle();

// null if haven't solved today's puzzle yet
const alreadySolvedSolution = loadSolutionFromLocalStorage();
// null if haven't revealed a letter yet
const alreadyRevealedLetter = loadRevealedLetterFromLocalStorage();

const GameContextProvider = ({ children }) => {
  const [solveState, setSolveState] = useState(alreadySolvedSolution || prompts);
  const [isSolved, setIsSolved] = useState(alreadySolvedSolution !== null);
  const [revealedLetter, setRevealedLetter] = useState(alreadyRevealedLetter);
  const [activeWord, setActiveWord] = useState(0);

  // indices of letters that player starts with (not blanks, which are represented by a '*')
  const promptLetters = prompts.map((prompt) =>
    prompt
      .split('')
      .map((letter, idx) => (letter !== '*' ? idx : null))
      .filter((letterIdx) => letterIdx !== null),
  );

  // indices of letters that player has revealed, not including ones that were included with the initial prompt
  const revealedLetters = solution.map((word, wordIdx) =>
    word
      .split('')
      .map((letter, letterIdx) =>
        !promptLetters[wordIdx].includes(letterIdx) && letter === revealedLetter ? letterIdx : null,
      )
      .filter((letterIdx) => letterIdx !== null),
  );

  // active letter shouldn't start on a prompt/revealed letter
  const initialActiveLetter = prompts[activeWord]
    .split('')
    .findIndex((c, idx) => c === '*' && !revealedLetters[activeWord].includes(idx));

  const [activeLetter, setActiveLetter] = useState(initialActiveLetter);

  useEffect(() => {
    // set working solve state to include newly revealed letters
    if (!isSolved && revealedLetter) {
      setSolveState((prevSolveState) =>
        prevSolveState.map((word, wordIdx) =>
          word
            .split('')
            .map((letter, letterIdx) =>
              revealedLetters[wordIdx].includes(letterIdx) ? solution[wordIdx][letterIdx] : letter,
            )
            .join(''),
        ),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revealedLetter, isSolved]);

  return (
    <GameContext.Provider
      value={{
        solveState,
        solution,
        activeWord,
        activeLetter,
        revealedLetter,
        revealedLetters,
        promptLetters,
        isSolved,
        setSolveState,
        setActiveWord,
        setActiveLetter,
        setRevealedLetter,
        setIsSolved,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export default GameContextProvider;
