export interface Puzzle {
  prompt: string[];
  solution: string[];
}

const PUZZLES: Puzzle[] = [
  { prompt: ['*ui**', 'mo***', '*x*u**'], solution: ['juice', 'moose', 'excuse'] },
  { prompt: ['f**', '*r*v**w', '*abo*'], solution: ['flu', 'preview', 'taboo'] },
  { prompt: ['*ne*', 'd**ri*', '*p*l*g*'], solution: ['knee', 'debris', 'apology'] },
  { prompt: ['p*ay**', 'me*ma**', 'su***'], solution: ['played', 'mermaid', 'suede'] },
  { prompt: ['o*e*', '*oo*a*', 'b**qu**'], solution: ['obey', 'hooray', 'bouquet'] },
  { prompt: ['*up*l*', 'r*b**', 'go*d***'], solution: ['supply', 'rabbi', 'goodbye'] },
  { prompt: ['wh**', '*l*ig*', 'd**pl**'], solution: ['whey', 'sleigh', 'display'] },
  { prompt: ['s*n*ro**', 'f*a*', '*o*b'], solution: ['syndrome', 'foam', 'comb'] },
  { prompt: ['pl***', '*ai*', '*e*g*'], solution: ['plane', 'main', 'feign'] },
  { prompt: ['b*a*', 'w*e**', 'c**e'], solution: ['bear', 'where', 'care'] },
  { prompt: ['f*n*', '*au**', 'wa**'], solution: ['font', 'haunt', 'want'] },
  { prompt: ['p**ss**', 'br**s*', '**gg***'], solution: ['pressed', 'breast', 'suggest'] },
  { prompt: ['*ee*', 's*a*', '*xp**ti**'], solution: ['fees', 'seas', 'expertise'] },
  { prompt: ['*w*', 's**', '*ut*a*'], solution: ['awe', 'spa', 'outlaw'] },
  { prompt: ['*qu***', 'te**', 'f*i*'], solution: ['square', 'tear', 'fair'] },
  { prompt: ['g*i**', 'c*m**n**', 'd**ig*e*'], solution: ['grind', 'combined', 'designed'] },
  { prompt: ['*rai*', 'p*ay**', '*ara**'], solution: ['braid', 'prayed', 'parade'] },
  { prompt: ['s*i*', 'b*ea*', 'f*e*'], solution: ['said', 'bread', 'fled'] },
  { prompt: ['d*te*', '*ti*', 'bl**'], solution: ['deter', 'stir', 'blur'] },
  { prompt: ['w**', 'c*ic**', '*u*fe*'], solution: ['way', 'cliche', 'buffet'] },
  { prompt: ['r*p*a*', '*om*le**', 'b*e*'], solution: ['repeat', 'complete', 'beet'] },
  { prompt: ['f*ni**', '*ig**', 'gi*a*y**'], solution: ['finite', 'sight', 'gigabyte'] },
  { prompt: ['c*im*', 't*y**', '*li**'], solution: ['chime', 'thyme', 'climb'] },
  { prompt: ['c*i*', 'b**k', 's*e*'], solution: ['chic', 'beak', 'seek'] },
  { prompt: ['*c*u*', 't**n*f**', 'we**'], solution: ['occur', 'transfer', 'were'] },
  { prompt: ['g*n*l*', 'me*t**', 'l***i*'], solution: ['gentle', 'mental', 'lentil'] },
  { prompt: ['pl*a**', '*e*z*', '*g*ee*'], solution: ['please', 'seize', 'agrees'] },
  { prompt: ['r**m*', '*im*', '**zy**'], solution: ['rhyme', 'time', 'enzyme'] },
  { prompt: ['ma*r*', 'pr**r**', 'f*i**'], solution: ['marry', 'prairie', 'fairy'] },
  { prompt: ['**ui**', 'w*os*', 'sho**'], solution: ['cruise', 'whose', 'shoes'] },
  { prompt: ['c*os**', 'de*o***', '*om*o*it*'], solution: ['closet', 'deposit', 'composite'] },
  { prompt: ['b*r**', '*hu*c*', 's*ar**'], solution: ['birch', 'church', 'search'] },
  { prompt: ['b**au**', '*oe*', 'bu**'], solution: ['because', 'does', 'buzz'] },
  { prompt: ['h*a*', '*le**', 'su*t*'], solution: ['heat', 'fleet', 'suite'] },
  { prompt: ['*ig*e*', 'f**e', '*ia*'], solution: ['higher', 'fire', 'liar'] },
  { prompt: ['*ou*', '*n*oo*', '*i*o*au*'], solution: ['your', 'indoor', 'dinosaur'] },
  { prompt: ['*me**', 'a*he**', '*ee*'], solution: ['smear', 'adhere', 'jeer'] },
  { prompt: ['*ig**', 'ha**', '*w*i*'], solution: ['eight', 'hate', 'await'] },
  { prompt: ['*c*oo*', 'c**e*', 'g*ou*'], solution: ['school', 'cruel', 'ghoul'] },
  { prompt: ['*ei**', '*ka*', '***ee'], solution: ['weigh', 'okay', 'melee'] },
  { prompt: ['*h*ou**', 'st*t**', '*oo*o*'], solution: ['through', 'statue', 'voodoo'] },
  { prompt: ['w***', '**yc***', '*o*o*'], solution: ['watt', 'boycott', 'robot'] },
  { prompt: ['*ru*s**', '*os**', 'u*e*'], solution: ['cruiser', 'loser', 'user'] },
  { prompt: ['g**s*', '*oa**', '*ia*n*s**'], solution: ['ghost', 'toast', 'diagnosed'] },
  { prompt: ['ac**', '*i*ta**', '**tb*e**'], solution: ['ache', 'mistake', 'outbreak'] },
  { prompt: ['***ic*', '*ig**', 'm***'], solution: ['indict', 'light', 'mite'] },
  { prompt: ['*o*', 'f*u*', '*l**'], solution: ['woe', 'faux', 'slow'] },
  { prompt: ['l**b', 'da**', '*c**'], solution: ['lamb', 'damn', 'scam'] },
  { prompt: ['*ru*', 's***', 'b*e*'], solution: ['true', 'shoe', 'brew'] },
  { prompt: ['*ha**', '*ff***', '*ei*'], solution: ['share', 'affair', 'heir'] },
  { prompt: ['ta**', '*o*', 'f*ug**'], solution: ['taut', 'rot', 'fought'] },
  { prompt: ['*aw*', '**up**', '*wa*'], solution: ['lawn', 'coupon', 'swan'] },
  { prompt: ['*lo*', 'p**i*', '*la*e**'], solution: ['aloe', 'patio', 'plateau'] },
  { prompt: ['p*e*', 'ch***', 's*v***'], solution: ['pier', 'cheer', 'severe'] },
  { prompt: ['ha**', '*e*o***', '*ma**'], solution: ['haul', 'aerosol', 'small'] },
  { prompt: ['*i*g*r**', '*r*a*', '*u*v**'], solution: ['lingerie', 'array', 'survey'] },
  { prompt: ['*au*', '**w*', 'cr***'], solution: ['maul', 'bawl', 'crawl'] },
  { prompt: ['s**f*', '*al*', '**to*r***'], solution: ['staff', 'half', 'autograph'] },
  { prompt: ['we***', 'b*a**', '*e*r**'], solution: ['weird', 'beard', 'feared'] },
  { prompt: ['*lu***', '*uc*', 'to***'], solution: ['clutch', 'such', 'touch'] },
  { prompt: ['w*a**', '*m*i*', 'v***'], solution: ['whale', 'email', 'veil'] },
  { prompt: ['*o*mi**e*', '*it*', '*re***'], solution: ['committee', 'city', 'pretty'] },
  { prompt: ['*o*', '*eg**', '*ny***'], solution: ['son', 'begun', 'anyone'] },
  { prompt: ['ti**', 'he**', '*a*'], solution: ['tier', 'here', 'ear'] },
  { prompt: ['****qu*', '*he**', 's**a*'], solution: ['oblique', 'cheek', 'sneak'] },
  { prompt: ['*w*a*', 'ca***', '*ru*e***'], solution: ['sweat', 'cadet', 'brunette'] },
  { prompt: ['s*ea*', 'p**y**', '*o*h***'], solution: ['swear', 'prayer', 'nowhere'] },
  { prompt: ['p*ai**', 'ha**', '*is*la**'], solution: ['praise', 'haze', 'displays'] },
  { prompt: ['*ou**', 'd***g**', '*ho**'], solution: ['doubt', 'drought', 'shout'] },
  { prompt: ['**v*t**', '**gh**', '*ei**t*'], solution: ['invites', 'tights', 'heights'] },
  { prompt: ['*au**', '***af**', 'b***l*'], solution: ['laugh', 'giraffe', 'behalf'] },
  { prompt: ['wa***', '**ugh***', '*tt**'], solution: ['water', 'daughter', 'otter'] },
  { prompt: ['*ai*', 'l**e', '*a*pa***'], solution: ['vain', 'lane', 'campaign'] },
  { prompt: ['b**', '*al**', '*h*ck*'], solution: ['box', 'talks', 'shocks'] },
  { prompt: ['h*m*', 'sl**', 'l**b'], solution: ['hymn', 'slim', 'limb'] },
  { prompt: ['**to*', '*coo***', 'c*m*u***'], solution: ['tutor', 'scooter', 'computer'] },
  { prompt: ['*us*ci**', 'a*d*t***', 'e**ssi**'], solution: ['musician', 'audition', 'emission'] },
  { prompt: ['*wa**', 'co**', 'b*r**'], solution: ['award', 'cord', 'bored'] },
  {
    prompt: ['*om*e*c**', 'c*nd***e*', '*g*in**'],
    solution: ['commenced', 'condensed', 'against'],
  },
  { prompt: ['c*n**', 'sh***', '*oca**'], solution: ['canal', 'shall', 'locale'] },
  { prompt: ['*lo*', 'sh*c*', '*h*l*'], solution: ['bloc', 'shock', 'chalk'] },
  { prompt: ['**twe**', 'pr*t***', '*ea*'], solution: ['between', 'protein', 'mean'] },
  { prompt: ['*ch***', '*egi**', '*ea*'], solution: ['scheme', 'regime', 'team'] },
  { prompt: ['*oo*', 'd**e', '*cre***'], solution: ['food', 'dude', 'screwed'] },
  { prompt: ['si**', '*u*pr***', '*na*y**'], solution: ['size', 'surprise', 'analyze'] },
  { prompt: ['*ea**', 'bu**', '*onc***'], solution: ['learn', 'burn', 'concern'] },
  { prompt: ['c**og**', 'ph***', 'k**w*'], solution: ['cologne', 'phone', 'known'] },
  { prompt: ['*om*', 'pe*f***', 'g*o**'], solution: ['tomb', 'perfume', 'groom'] },
  { prompt: ['w*i**', '*as**', 'e*b*a***'], solution: ['waist', 'based', 'embraced'] },
  { prompt: ['m*a*', '*he**', 'r***ip*'], solution: ['meat', 'sheet', 'receipt'] },
  { prompt: ['*os*e*', 'co**', '*x*au**'], solution: ['tossed', 'cost', 'exhaust'] },
  { prompt: ['s*o***', '*oa*', '*p*so**'], solution: ['showed', 'load', 'episode'] },
  { prompt: ['s*y**', '*ri**', 'wh***'], solution: ['style', 'trial', 'while'] },
  { prompt: ['n**l*', '*ob***', 'g*o*a*'], solution: ['noble', 'mobile', 'global'] },
  { prompt: ['c*f*ei**', '*c*n*', '*ou*i**'], solution: ['caffeine', 'scene', 'routine'] },
  { prompt: ['*ow*', 'sc*o**', 'g*a*'], solution: ['bowl', 'scroll', 'goal'] },
  { prompt: ['b**s', 'c*s*', 'gr***'], solution: ['bass', 'case', 'grace'] },
  { prompt: ['e**', '*p*l*', '*hi**'], solution: ['eye', 'apply', 'thigh'] },
  { prompt: ['*ou*', 'wh***', '**tr**'], solution: ['soul', 'whole', 'patrol'] },
  { prompt: ['go**', '*pp***', '*h*w*'], solution: ['goes', 'oppose', 'shows'] },
  { prompt: ['*te*n**', '*our***', 'c*lo***'], solution: ['eternal', 'journal', 'colonel'] },
  { prompt: ['*hu**', '*om*', 'dr**'], solution: ['thumb', 'come', 'drum'] },
  { prompt: ['*bs***', '*or*', 'd*fe**e*'], solution: ['absurd', 'word', 'deferred'] },
  { prompt: ['*r*w**', 'co**', '*l*o**'], solution: ['browse', 'cows', 'allows'] },
  { prompt: ['s*al***', '*ol***', '*ch**a*'], solution: ['smaller', 'dollar', 'scholar'] },
  { prompt: ['di**', '*ly**', '*upp****'], solution: ['dire', 'flyer', 'supplier'] },
  { prompt: ['e*r**', 'bi***', '*or**'], solution: ['earth', 'birth', 'worth'] },
  { prompt: ['f*r**', 'ho***', 'c*u***'], solution: ['force', 'horse', 'course'] },
  { prompt: ['f*a***', '*qu**', '*r*u*'], solution: ['flawed', 'squad', 'fraud'] },
  { prompt: ['t*i**', '*sl*', '**of***'], solution: ['trial', 'isle', 'profile'] },
  { prompt: ['*iv**i**', 'pa*il***', 'mi***o*'], solution: ['civilian', 'pavilion', 'million'] },
  { prompt: ['**yl**', 'f**e*', 'wi**'], solution: ['styled', 'filed', 'wild'] },
  { prompt: ['te**', 'wo**', '*ff***'], solution: ['term', 'worm', 'affirm'] },
  { prompt: ['*ea***', '*re**e*', 'ce*a*'], solution: ['leader', 'breeder', 'cedar'] },
  { prompt: ['*eb*', '*we**', '**ss*t**'], solution: ['debt', 'sweat', 'cassette'] },
  { prompt: ['t*ic**', 'f**', '*ol*t***'], solution: ['tricks', 'fix', 'politics'] },
  { prompt: ['*e*s*', '*omm****', '*e*t*'], solution: ['dense', 'commence', 'cents'] },
  { prompt: ['fl****', '*ie**', '*b*s*'], solution: ['fleece', 'piece', 'obese'] },
  { prompt: ['*au**', 'bo**', '*a*ro***'], solution: ['sauce', 'boss', 'lacrosse'] },
  { prompt: ['o**', 'br***', 'g**'], solution: ['odd', 'broad', 'god'] },
  { prompt: ['**on*e', 'wa**', '*e*po**'], solution: ['blonde', 'wand', 'respond'] },
  { prompt: ['*er**', 'w*a**', '*heo**'], solution: ['eerie', 'weary', 'theory'] },
  { prompt: ['*al*', 'p*o*', '*o*b'], solution: ['calm', 'prom', 'bomb'] },
  { prompt: ['*rie**', '*e*s*', 'ce****'], solution: ['priest', 'feast', 'ceased'] },
  { prompt: ['**lu**', '**ui*', 'ro**'], solution: ['salute', 'fruit', 'root'] },
  { prompt: ['le***', '**ee**', 'n*i**'], solution: ['leave', 'sleeve', 'naive'] },
  { prompt: ['*ll**', 'ra***', 'fi***e'], solution: ['alley', 'rally', 'finale'] },
  { prompt: ['*o*g**', '*un*', 'am***'], solution: ['tongue', 'hung', 'among'] },
  { prompt: ['*oo**', '*ru**', 'yo***'], solution: ['booth', 'truth', 'youth'] },
  { prompt: ['*et**', 'h*a***', '*i*e*'], solution: ['meter', 'heater', 'liter'] },
  { prompt: ['t**c*', 'lo***', '*bu**'], solution: ['truce', 'loose', 'abuse'] },
  { prompt: ['b*e*', '*hi**', 'm*t**'], solution: ['beef', 'chief', 'motif'] },
  { prompt: ['*o**', 'wa**', '*r*to***'], solution: ['doll', 'wall', 'protocol'] },
  { prompt: ['*li**', '*u**', 'a*e**'], solution: ['flirt', 'hurt', 'alert'] },
  { prompt: ['c**o*', '*ar***', 'a*p**e*'], solution: ['carol', 'barrel', 'apparel'] },
  { prompt: ['d*a*', 'f*e*', 'j****'], solution: ['dual', 'fuel', 'jewel'] },
  { prompt: ['w*i*p**', '*cr***', '**y**'], solution: ['whipped', 'script', 'crypt'] },
  { prompt: ['*tr***', '*a*', '*ol*'], solution: ['stroke', 'oak', 'folk'] },
  { prompt: ['*u**', 'fe*o**', '*o*a*'], solution: ['aura', 'fedora', 'torah'] },
  { prompt: ['*ed***', '*oo**', 'a*u**'], solution: ['reduce', 'loose', 'abuse'] },
  { prompt: ['e**', '**d*', '*nt**v***'], solution: ['emu', 'undo', 'interview'] },
  { prompt: ['*fr***', '**ig***', 'p*ra**'], solution: ['afraid', 'weighed', 'parade'] },
  { prompt: ['**u*m**', 'd**a*', 's**v**'], solution: ['gourmet', 'decay', 'survey'] },
  { prompt: ['w**', '*er****', '*a*u*a*'], solution: ['why', 'certify', 'samurai'] },
  { prompt: ['g*i*', 'm**ha**', '**am**g**'], solution: ['gain', 'methane', 'champagne'] },
  { prompt: ['d*c*a**', '*ea*', '**li*ai**'], solution: ['declare', 'bear', 'solitaire'] },
  { prompt: ['t*a**', '*ey*', '**mi*e**'], solution: ['tease', 'keys', 'nominees'] },
  { prompt: ['*a*e', 'st*i*', '*he**'], solution: ['rare', 'stair', 'where'] },
  { prompt: ['*em***', '**ig*e*', 'd*f****'], solution: ['remind', 'aligned', 'defined'] },
  { prompt: ['*ai*', 'bl***', '**r*ua**'], solution: ['paid', 'blade', 'persuade'] },
  { prompt: ['*r*f**', '**cu*', 'a**te**'], solution: ['prefer', 'occur', 'amateur'] },
  { prompt: ['*r*a*', '*om*e**', 'f***'], solution: ['treat', 'compete', 'feet'] },
  { prompt: ['*ri**', 'e**y**', 'p**a*ig*'], solution: ['prime', 'enzyme', 'paradigm'] },
  { prompt: ['*l*a*', '*re**', '**ch**q**'], solution: ['bleak', 'creek', 'technique'] },
  { prompt: ['l***', 'c*oo**', '**ff***'], solution: ['lose', 'choose', 'diffuse'] },
  { prompt: ['pe*i**', 'm*a*', '*he**'], solution: ['petite', 'meat', 'sheet'] },
  { prompt: ['*ir*', '*ho**', 'b**e*'], solution: ['dire', 'choir', 'buyer'] },
  { prompt: ['*i*ce**', 'c*e**', '**gi**e*'], solution: ['sincere', 'clear', 'engineer'] },
  { prompt: ['g*e**', '*ei***', '**a*e'], solution: ['great', 'weight', 'state'] },
  { prompt: ['*lu*', 'c**p', '*a*to*'], solution: ['glue', 'coup', 'tattoo'] },
  { prompt: ['*no*', 'bo**h*', 'y*c**'], solution: ['knot', 'bought', 'yacht'] },
  { prompt: ['q*a**', '**ea*', '*ea**c**'], solution: ['quake', 'steak', 'headache'] },
  { prompt: ['*pa**', '*o*e', '**to*'], solution: ['spawn', 'gone', 'baton'] },
  { prompt: ['*e*ai*', 'sc***', '**ail**'], solution: ['detail', 'scale', 'braille'] },
  { prompt: ['s*u*', '*o**', '**meo**'], solution: ['spun', 'none', 'someone'] },
  { prompt: ['*p*e**', 'be**', '**uv**i*'], solution: ['sphere', 'beer', 'souvenir'] },
  { prompt: ['c*im*', 't*y**', '*li**'], solution: ['chime', 'thyme', 'climb'] },
  { prompt: ['*ne*', 'd**ri*', '*p*l*g*'], solution: ['knee', 'debris', 'apology'] },
  { prompt: ['pl*a**', '*e*z*', '*g*ee*'], solution: ['please', 'seize', 'agrees'] },
  { prompt: ['w**', 'c*ic**', '*u*fe*'], solution: ['way', 'cliche', 'buffet'] },
  { prompt: ['pl***', '*ai*', '*e*g*'], solution: ['plane', 'main', 'feign'] },
  { prompt: ['d*te*', '*ti*', 'bl**'], solution: ['deter', 'stir', 'blur'] },
  { prompt: ['b*r**', '*hu*c*', 's*ar**'], solution: ['birch', 'church', 'search'] },
  { prompt: ['b*a*', 'w*e**', 'c**e'], solution: ['bear', 'where', 'care'] },
  { prompt: ['*me**', 'a*he**', '*ee*'], solution: ['smear', 'adhere', 'jeer'] },
  { prompt: ['c*os**', 'de*o***', '*om*o*it*'], solution: ['closet', 'deposit', 'composite'] },
  { prompt: ['c*i*', 'b**k', 's*e*'], solution: ['chic', 'beak', 'seek'] },
  { prompt: ['f**', '*r*v**w', '*abo*'], solution: ['flu', 'preview', 'taboo'] },
  { prompt: ['**ui**', 'w*os*', 'sho**'], solution: ['cruise', 'whose', 'shoes'] },
  { prompt: ['*up*l*', 'r*b**', 'go*d***'], solution: ['supply', 'rabbi', 'goodbye'] },
  { prompt: ['*c*oo*', 'c**e*', 'g*ou*'], solution: ['school', 'cruel', 'ghoul'] },
  { prompt: ['r*p*a*', '*om*le**', 'b*e*'], solution: ['repeat', 'complete', 'beet'] },
  { prompt: ['p**ss**', 'br**s*', '**gg***'], solution: ['pressed', 'breast', 'suggest'] },
  { prompt: ['*ou*', '*n*oo*', '*i*o*au*'], solution: ['your', 'indoor', 'dinosaur'] },
  { prompt: ['*ig*e*', 'f**e', '*ia*'], solution: ['higher', 'fire', 'liar'] },
  { prompt: ['f*n*', '*au**', 'wa**'], solution: ['font', 'haunt', 'want'] },
  { prompt: ['*ui**', 'mo***', '*x*u**'], solution: ['juice', 'moose', 'excuse'] },
  { prompt: ['*qu***', 'te**', 'f*i*'], solution: ['square', 'tear', 'fair'] },
  { prompt: ['*w*', 's**', '*ut*a*'], solution: ['awe', 'spa', 'outlaw'] },
  { prompt: ['*c*u*', 't**n*f**', 'we**'], solution: ['occur', 'transfer', 'were'] },
  { prompt: ['ma*r*', 'pr**r**', 'f*i**'], solution: ['marry', 'prairie', 'fairy'] },
  { prompt: ['o*e*', '*oo*a*', 'b**qu**'], solution: ['obey', 'hooray', 'bouquet'] },
  { prompt: ['r**m*', '*im*', '**zy**'], solution: ['rhyme', 'time', 'enzyme'] },
  { prompt: ['p*ay**', 'me*ma**', 'su***'], solution: ['played', 'mermaid', 'suede'] },
  { prompt: ['s*i*', 'b*ea*', 'f*e*'], solution: ['said', 'bread', 'fled'] },
  { prompt: ['b**au**', '*oe*', 'bu**'], solution: ['because', 'does', 'buzz'] },
  { prompt: ['*ee*', 's*a*', '*xp**ti**'], solution: ['fees', 'seas', 'expertise'] },
  { prompt: ['*rai*', 'p*ay**', '*ara**'], solution: ['braid', 'prayed', 'parade'] },
  { prompt: ['s*n*ro**', 'f*a*', '*o*b'], solution: ['syndrome', 'foam', 'comb'] },
  { prompt: ['*ig**', 'ha**', '*w*i*'], solution: ['eight', 'hate', 'await'] },
  { prompt: ['wh**', '*l*ig*', 'd**pl**'], solution: ['whey', 'sleigh', 'display'] },
  { prompt: ['g*n*l*', 'me*t**', 'l***i*'], solution: ['gentle', 'mental', 'lentil'] },
  { prompt: ['h*a*', '*le**', 'su*t*'], solution: ['heat', 'fleet', 'suite'] },
  { prompt: ['*ei**', '*ka*', '***ee'], solution: ['weigh', 'okay', 'melee'] },
  { prompt: ['g*i**', 'c*m**n**', 'd**ig*e*'], solution: ['grind', 'combined', 'designed'] },
  { prompt: ['f*ni**', '*ig**', 'gi*a*y**'], solution: ['finite', 'sight', 'gigabyte'] },
  { prompt: ['*au*', '**w*', 'cr***'], solution: ['maul', 'bawl', 'crawl'] },
  { prompt: ['*ai*', 'l**e', '*a*pa***'], solution: ['vain', 'lane', 'campaign'] },
  { prompt: ['s*ea*', 'p**y**', '*o*h***'], solution: ['swear', 'prayer', 'nowhere'] },
  { prompt: ['*aw*', '**up**', '*wa*'], solution: ['lawn', 'coupon', 'swan'] },
  { prompt: ['**v*t**', '**gh**', '*ei**t*'], solution: ['invites', 'tights', 'heights'] },
  { prompt: ['***ic*', '*ig**', 'm***'], solution: ['indict', 'light', 'mite'] },
  { prompt: ['*us*ci**', 'a*d*t***', 'e**ssi**'], solution: ['musician', 'audition', 'emission'] },
  { prompt: ['h*m*', 'sl**', 'l**b'], solution: ['hymn', 'slim', 'limb'] },
  { prompt: ['w***', '**yc***', '*o*o*'], solution: ['watt', 'boycott', 'robot'] },
  { prompt: ['*o*', 'f*u*', '*l**'], solution: ['woe', 'faux', 'slow'] },
  { prompt: ['*lu***', '*uc*', 'to***'], solution: ['clutch', 'such', 'touch'] },
  { prompt: ['ac**', '*i*ta**', '**tb*e**'], solution: ['ache', 'mistake', 'outbreak'] },
  { prompt: ['g**s*', '*oa**', '*ia*n*s**'], solution: ['ghost', 'toast', 'diagnosed'] },
  { prompt: ['c*n**', 'sh***', '*oca**'], solution: ['canal', 'shall', 'locale'] },
  { prompt: ['*ou**', 'd***g**', '*ho**'], solution: ['doubt', 'drought', 'shout'] },
  { prompt: ['b**', '*al**', '*h*ck*'], solution: ['box', 'talks', 'shocks'] },
  { prompt: ['s**f*', '*al*', '**to*r***'], solution: ['staff', 'half', 'autograph'] },
  { prompt: ['*w*a*', 'ca***', '*ru*e***'], solution: ['sweat', 'cadet', 'brunette'] },
  { prompt: ['****qu*', '*he**', 's**a*'], solution: ['oblique', 'cheek', 'sneak'] },
  { prompt: ['*o*', '*eg**', '*ny***'], solution: ['son', 'begun', 'anyone'] },
  { prompt: ['*wa**', 'co**', 'b*r**'], solution: ['award', 'cord', 'bored'] },
  { prompt: ['*au**', '***af**', 'b***l*'], solution: ['laugh', 'giraffe', 'behalf'] },
  { prompt: ['*ru*', 's***', 'b*e*'], solution: ['true', 'shoe', 'brew'] },
  { prompt: ['we***', 'b*a**', '*e*r**'], solution: ['weird', 'beard', 'feared'] },
  { prompt: ['*h*ou**', 'st*t**', '*oo*o*'], solution: ['through', 'statue', 'voodoo'] },
  {
    prompt: ['*om*e*c**', 'c*nd***e*', '*g*in**'],
    solution: ['commenced', 'condensed', 'against'],
  },
  { prompt: ['*lo*', 'p**i*', '*la*e**'], solution: ['aloe', 'patio', 'plateau'] },
  { prompt: ['ha**', '*e*o***', '*ma**'], solution: ['haul', 'aerosol', 'small'] },
  { prompt: ['*lo*', 'sh*c*', '*h*l*'], solution: ['bloc', 'shock', 'chalk'] },
  { prompt: ['*ru*s**', '*os**', 'u*e*'], solution: ['cruiser', 'loser', 'user'] },
  { prompt: ['p*ai**', 'ha**', '*is*la**'], solution: ['praise', 'haze', 'displays'] },
  { prompt: ['ti**', 'he**', '*a*'], solution: ['tier', 'here', 'ear'] },
  { prompt: ['l**b', 'da**', '*c**'], solution: ['lamb', 'damn', 'scam'] },
  { prompt: ['**to*', '*coo***', 'c*m*u***'], solution: ['tutor', 'scooter', 'computer'] },
  { prompt: ['ta**', '*o*', 'f*ug**'], solution: ['taut', 'rot', 'fought'] },
  { prompt: ['p*e*', 'ch***', 's*v***'], solution: ['pier', 'cheer', 'severe'] },
  { prompt: ['w*a**', '*m*i*', 'v***'], solution: ['whale', 'email', 'veil'] },
  { prompt: ['wa***', '**ugh***', '*tt**'], solution: ['water', 'daughter', 'otter'] },
  { prompt: ['*o*mi**e*', '*it*', '*re***'], solution: ['committee', 'city', 'pretty'] },
  { prompt: ['*ha**', '*ff***', '*ei*'], solution: ['share', 'affair', 'heir'] },
  { prompt: ['*i*g*r**', '*r*a*', '*u*v**'], solution: ['lingerie', 'array', 'survey'] },
  { prompt: ['f*a***', '*qu**', '*r*u*'], solution: ['flawed', 'squad', 'fraud'] },
  { prompt: ['*ch***', '*egi**', '*ea*'], solution: ['scheme', 'regime', 'team'] },
  { prompt: ['*eb*', '*we**', '**ss*t**'], solution: ['debt', 'sweat', 'cassette'] },
  { prompt: ['*ou*', 'wh***', '**tr**'], solution: ['soul', 'whole', 'patrol'] },
  { prompt: ['*r*w**', 'co**', '*l*o**'], solution: ['browse', 'cows', 'allows'] },
  { prompt: ['c**og**', 'ph***', 'k**w*'], solution: ['cologne', 'phone', 'known'] },
  { prompt: ['*te*n**', '*our***', 'c*lo***'], solution: ['eternal', 'journal', 'colonel'] },
  { prompt: ['si**', '*u*pr***', '*na*y**'], solution: ['size', 'surprise', 'analyze'] },
  { prompt: ['s*o***', '*oa*', '*p*so**'], solution: ['showed', 'load', 'episode'] },
  { prompt: ['go**', '*pp***', '*h*w*'], solution: ['goes', 'oppose', 'shows'] },
  { prompt: ['**on*e', 'wa**', '*e*po**'], solution: ['blonde', 'wand', 'respond'] },
  { prompt: ['c*f*ei**', '*c*n*', '*ou*i**'], solution: ['caffeine', 'scene', 'routine'] },
  { prompt: ['e**', '*p*l*', '*hi**'], solution: ['eye', 'apply', 'thigh'] },
  { prompt: ['t*ic**', 'f**', '*ol*t***'], solution: ['tricks', 'fix', 'politics'] },
  { prompt: ['*ea**', 'bu**', '*onc***'], solution: ['learn', 'burn', 'concern'] },
  { prompt: ['*iv**i**', 'pa*il***', 'mi***o*'], solution: ['civilian', 'pavilion', 'million'] },
  { prompt: ['n**l*', '*ob***', 'g*o*a*'], solution: ['noble', 'mobile', 'global'] },
  { prompt: ['di**', '*ly**', '*upp****'], solution: ['dire', 'flyer', 'supplier'] },
  { prompt: ['*e*s*', '*omm****', '*e*t*'], solution: ['dense', 'commence', 'cents'] },
  { prompt: ['s*al***', '*ol***', '*ch**a*'], solution: ['smaller', 'dollar', 'scholar'] },
  { prompt: ['fl****', '*ie**', '*b*s*'], solution: ['fleece', 'piece', 'obese'] },
  { prompt: ['*au**', 'bo**', '*a*ro***'], solution: ['sauce', 'boss', 'lacrosse'] },
  { prompt: ['*oo*', 'd**e', '*cre***'], solution: ['food', 'dude', 'screwed'] },
  { prompt: ['*ow*', 'sc*o**', 'g*a*'], solution: ['bowl', 'scroll', 'goal'] },
  { prompt: ['w*i**', '*as**', 'e*b*a***'], solution: ['waist', 'based', 'embraced'] },
  { prompt: ['e*r**', 'bi***', '*or**'], solution: ['earth', 'birth', 'worth'] },
  { prompt: ['te**', 'wo**', '*ff***'], solution: ['term', 'worm', 'affirm'] },
  { prompt: ['**twe**', 'pr*t***', '*ea*'], solution: ['between', 'protein', 'mean'] },
  { prompt: ['m*a*', '*he**', 'r***ip*'], solution: ['meat', 'sheet', 'receipt'] },
  { prompt: ['**yl**', 'f**e*', 'wi**'], solution: ['styled', 'filed', 'wild'] },
  { prompt: ['*er**', 'w*a**', '*heo**'], solution: ['eerie', 'weary', 'theory'] },
  { prompt: ['t*i**', '*sl*', '**of***'], solution: ['trial', 'isle', 'profile'] },
  { prompt: ['f*r**', 'ho***', 'c*u***'], solution: ['force', 'horse', 'course'] },
  { prompt: ['o**', 'br***', 'g**'], solution: ['odd', 'broad', 'god'] },
  { prompt: ['*om*', 'pe*f***', 'g*o**'], solution: ['tomb', 'perfume', 'groom'] },
  { prompt: ['s*y**', '*ri**', 'wh***'], solution: ['style', 'trial', 'while'] },
  { prompt: ['*hu**', '*om*', 'dr**'], solution: ['thumb', 'come', 'drum'] },
  { prompt: ['*ea***', '*re**e*', 'ce*a*'], solution: ['leader', 'breeder', 'cedar'] },
  { prompt: ['*os*e*', 'co**', '*x*au**'], solution: ['tossed', 'cost', 'exhaust'] },
  { prompt: ['*bs***', '*or*', 'd*fe**e*'], solution: ['absurd', 'word', 'deferred'] },
  { prompt: ['b**s', 'c*s*', 'gr***'], solution: ['bass', 'case', 'grace'] },
  { prompt: ['s*u*', '*o**', '**meo**'], solution: ['spun', 'none', 'someone'] },
  { prompt: ['*em***', '**ig*e*', 'd*f****'], solution: ['remind', 'aligned', 'defined'] },
  { prompt: ['*ai*', 'bl***', '**r*ua**'], solution: ['paid', 'blade', 'persuade'] },
  { prompt: ['**u*m**', 'd**a*', 's**v**'], solution: ['gourmet', 'decay', 'survey'] },
  { prompt: ['*l*a*', '*re**', '**ch**q**'], solution: ['bleak', 'creek', 'technique'] },
  { prompt: ['*r*a*', '*om*e**', 'f***'], solution: ['treat', 'compete', 'feet'] },
  { prompt: ['*o**', 'wa**', '*r*to***'], solution: ['doll', 'wall', 'protocol'] },
  { prompt: ['**lu**', '**ui*', 'ro**'], solution: ['salute', 'fruit', 'root'] },
  { prompt: ['*a*e', 'st*i*', '*he**'], solution: ['rare', 'stair', 'where'] },
  { prompt: ['*rie**', '*e*s*', 'ce****'], solution: ['priest', 'feast', 'ceased'] },
  { prompt: ['*lu*', 'c**p', '*a*to*'], solution: ['glue', 'coup', 'tattoo'] },
  { prompt: ['*r*f**', '**cu*', 'a**te**'], solution: ['prefer', 'occur', 'amateur'] },
  { prompt: ['g*i*', 'm**ha**', '**am**g**'], solution: ['gain', 'methane', 'champagne'] },
  { prompt: ['*e*ai*', 'sc***', '**ail**'], solution: ['detail', 'scale', 'braille'] },
  { prompt: ['e**', '**d*', '*nt**v***'], solution: ['emu', 'undo', 'interview'] },
  { prompt: ['t**c*', 'lo***', '*bu**'], solution: ['truce', 'loose', 'abuse'] },
  { prompt: ['w**', '*er****', '*a*u*a*'], solution: ['why', 'certify', 'samurai'] },
  { prompt: ['*pa**', '*o*e', '**to*'], solution: ['spawn', 'gone', 'baton'] },
  { prompt: ['*no*', 'bo**h*', 'y*c**'], solution: ['knot', 'bought', 'yacht'] },
  { prompt: ['*tr***', '*a*', '*ol*'], solution: ['stroke', 'oak', 'folk'] },
  { prompt: ['w*i*p**', '*cr***', '**y**'], solution: ['whipped', 'script', 'crypt'] },
  { prompt: ['*oo**', '*ru**', 'yo***'], solution: ['booth', 'truth', 'youth'] },
  { prompt: ['b*e*', '*hi**', 'm*t**'], solution: ['beef', 'chief', 'motif'] },
  { prompt: ['*o*g**', '*un*', 'am***'], solution: ['tongue', 'hung', 'among'] },
  { prompt: ['*i*ce**', 'c*e**', '**gi**e*'], solution: ['sincere', 'clear', 'engineer'] },
  { prompt: ['*fr***', '**ig***', 'p*ra**'], solution: ['afraid', 'weighed', 'parade'] },
  { prompt: ['d*c*a**', '*ea*', '**li*ai**'], solution: ['declare', 'bear', 'solitaire'] },
  { prompt: ['*li**', '*u**', 'a*e**'], solution: ['flirt', 'hurt', 'alert'] },
  { prompt: ['pe*i**', 'm*a*', '*he**'], solution: ['petite', 'meat', 'sheet'] },
  { prompt: ['l***', 'c*oo**', '**ff***'], solution: ['lose', 'choose', 'diffuse'] },
  { prompt: ['*u**', 'fe*o**', '*o*a*'], solution: ['aura', 'fedora', 'torah'] },
  { prompt: ['d*a*', 'f*e*', 'j****'], solution: ['dual', 'fuel', 'jewel'] },
  { prompt: ['q*a**', '**ea*', '*ea**c**'], solution: ['quake', 'steak', 'headache'] },
  { prompt: ['g*e**', '*ei***', '**a*e'], solution: ['great', 'weight', 'state'] },
  { prompt: ['c**o*', '*ar***', 'a*p**e*'], solution: ['carol', 'barrel', 'apparel'] },
  { prompt: ['*et**', 'h*a***', '*i*e*'], solution: ['meter', 'heater', 'liter'] },
  { prompt: ['*ir*', '*ho**', 'b**e*'], solution: ['dire', 'choir', 'buyer'] },
  { prompt: ['*al*', 'p*o*', '*o*b'], solution: ['calm', 'prom', 'bomb'] },
  { prompt: ['*ri**', 'e**y**', 'p**a*ig*'], solution: ['prime', 'enzyme', 'paradigm'] },
  { prompt: ['*p*e**', 'be**', '**uv**i*'], solution: ['sphere', 'beer', 'souvenir'] },
  { prompt: ['*ed***', '*oo**', 'a*u**'], solution: ['reduce', 'loose', 'abuse'] },
  { prompt: ['*ll**', 'ra***', 'fi***e'], solution: ['alley', 'rally', 'finale'] },
  { prompt: ['t*a**', '*ey*', '**mi*e**'], solution: ['tease', 'keys', 'nominees'] },
  { prompt: ['le***', '**ee**', 'n*i**'], solution: ['leave', 'sleeve', 'naive'] },
];

export default PUZZLES;
