import { useContext, useEffect } from 'react';
import { GameContext } from '../state/GameContext';
import { moveBackward, moveForward, moveDown, moveUp } from '../utils/navigation';

const useKeydownHandlers = ({ handleEnter, handleLetter, handleDelete }) => {
  const gameState = useContext(GameContext);
  const { setActiveWord, setActiveLetter } = gameState;

  const handleKeyDown = (e: KeyboardEvent) => {
    if (
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown'
    ) {
      let newActiveWord;
      let newActiveLetter;

      switch (e.key) {
        case 'ArrowLeft':
          newActiveWord = moveBackward(gameState).activeWord;
          newActiveLetter = moveBackward(gameState).activeLetter;
          break;
        case 'ArrowRight':
          newActiveWord = moveForward(gameState).activeWord;
          newActiveLetter = moveForward(gameState).activeLetter;
          break;
        case 'ArrowUp':
          newActiveWord = moveUp(gameState).activeWord;
          newActiveLetter = moveUp(gameState).activeLetter;
          break;
        case 'ArrowDown':
          newActiveWord = moveDown(gameState).activeWord;
          newActiveLetter = moveDown(gameState).activeLetter;
          break;
      }

      setActiveWord(newActiveWord);
      setActiveLetter(newActiveLetter);
    } else if (e.key === 'Enter') {
      handleEnter();
    } else if (e.key === 'Backspace') {
      handleDelete();
    } else if (!e.metaKey) {
      const { keyCode } = e;
      if (keyCode >= 65 && keyCode <= 90) {
        handleLetter(e.key.toLowerCase());
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  });
};

export default useKeydownHandlers;
