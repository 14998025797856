import { UAParser } from 'ua-parser-js';
import { ContextState } from '../state/GameContext';
import { getTodaysPuzzleIndex } from './promptFunctions';

const webShareApiDeviceTypes: string[] = ['mobile', 'smarttv', 'wearable'];
const parser = new UAParser();
const browser = parser.getBrowser();
const device = parser.getDevice();

export const shareStatus = (gameState: ContextState, handleShareToClipboard: () => void) => {
  const puzzleIndex = getTodaysPuzzleIndex();

  const textToShare = `Rhyme Time #${puzzleIndex + 1}\n${makeEmojiGrid(
    gameState,
  )}\nhttps://rhymetime.co.`;
  const shareData = { text: textToShare };

  let shareSuccess = false;

  try {
    if (attemptShare(shareData)) {
      navigator.share(shareData);
      shareSuccess = true;
    }
  } catch (error) {
    shareSuccess = false;
  }

  if (!shareSuccess) {
    navigator.clipboard.writeText(textToShare);
    handleShareToClipboard();
  }
};

const attemptShare = (shareData: object) => {
  return (
    // Deliberately exclude Firefox Mobile, because its Web Share API isn't working correctly
    browser.name?.toUpperCase().indexOf('FIREFOX') === -1 &&
    webShareApiDeviceTypes.indexOf(device.type ?? '') !== -1 &&
    navigator.canShare &&
    navigator.canShare(shareData) &&
    navigator.share
  );
};

const makeEmojiGrid = ({ solveState, revealedLetters, promptLetters }: ContextState): string => {
  return solveState
    .map((word, wordIdx) => {
      return word
        .split('')
        .map((_, letterIdx) => {
          if (revealedLetters[wordIdx].includes(letterIdx)) {
            return '🟦';
          } else if (promptLetters[wordIdx].includes(letterIdx)) {
            return '⬛';
          } else {
            return '🟩';
          }
        })
        .join('');
    })
    .join('\n');
};
