import { ContextState } from '../state/GameContext';
import { times } from 'lodash';

export const moveForward = (state: ContextState): { activeWord: number; activeLetter: number } => {
  const { solveState, activeLetter, activeWord, promptLetters, revealedLetters } = state;

  const nextLetterInActiveWord = solveState[activeWord]
    .split('')
    .findIndex(
      (_, idx) =>
        idx > activeLetter &&
        !promptLetters[activeWord].includes(idx) &&
        !revealedLetters[activeWord].includes(idx),
    );

  // if -1, either on last letter of word, or every remaining letter in word is from the prompt
  if (nextLetterInActiveWord > -1) {
    return {
      activeWord,
      activeLetter: nextLetterInActiveWord,
    };
  }

  // find next word with non-prompt squares
  const nextAvailableWord = solveState.findIndex(
    (word, wordIdx) =>
      wordIdx > activeWord &&
      word
        .split('')
        .some(
          (_, letterIdx) =>
            !promptLetters[wordIdx].includes(letterIdx) &&
            !revealedLetters[wordIdx].includes(letterIdx),
        ),
  );

  // if -1, either on last word, or every remaining word only has prompt letters
  if (nextAvailableWord > -1) {
    const nextAvailableLetter = solveState[nextAvailableWord]
      .split('')
      .findIndex(
        (_, idx) =>
          !promptLetters[nextAvailableWord].includes(idx) &&
          !revealedLetters[nextAvailableWord].includes(idx),
      );

    return {
      activeWord: nextAvailableWord,
      activeLetter: nextAvailableLetter,
    };
  }

  // nowhere to go, stay put!
  return {
    activeWord,
    activeLetter,
  };
};

// same logic as move forward, but reverses array indexing in order to traverse right to left,
// then subtracts found indices from the length of the array to get the real index
export const moveBackward = (state: ContextState): { activeWord: number; activeLetter: number } => {
  const { solveState, activeLetter, activeWord, promptLetters, revealedLetters } = state;

  const activeWordLength = solveState[activeWord].length;

  const reversedPrevLetterInActiveWord = times(solveState[activeWord].length, (i) => i + 1).find(
    (idx) =>
      idx > activeWordLength - activeLetter &&
      !promptLetters[activeWord].includes(activeWordLength - idx) &&
      !revealedLetters[activeWord].includes(activeWordLength - idx),
  );

  // if undefined, either on first letter of word, or every prior letter in word is from the prompt
  if (reversedPrevLetterInActiveWord !== undefined) {
    const prevLetterInActiveWord = solveState[activeWord].length - reversedPrevLetterInActiveWord;

    return {
      activeWord,
      activeLetter: prevLetterInActiveWord,
    };
  }

  const reversedPrevAvailableWord = times(solveState.length, (i) => i + 1).find((idx) => {
    const hasNonPromptOrRevealedLetters = solveState[solveState.length - idx]
      .split('')
      .some(
        (_, letterIdx) =>
          !promptLetters[solveState.length - idx].includes(letterIdx) &&
          !revealedLetters[solveState.length - idx].includes(letterIdx),
      );

    return idx > solveState.length - activeWord && hasNonPromptOrRevealedLetters;
  });

  // if undefined, either on first word, or every prior word only has prompt letters
  if (reversedPrevAvailableWord !== undefined) {
    const prevAvailableWord = solveState.length - reversedPrevAvailableWord;

    const reversedPrevAvailableLetter = times(
      solveState[prevAvailableWord].length,
      (i) => i + 1,
    ).find((idx) => {
      return (
        !promptLetters[prevAvailableWord].includes(solveState[prevAvailableWord].length - idx) &&
        !revealedLetters[prevAvailableWord].includes(solveState[prevAvailableWord].length - idx)
      );
    });

    const prevAvailableLetter = solveState[prevAvailableWord].length - reversedPrevAvailableLetter;

    return {
      activeWord: prevAvailableWord,
      activeLetter: prevAvailableLetter,
    };
  }

  // nowhere to go, stay put!
  return {
    activeWord,
    activeLetter,
  };
};

export const moveDown = (state: ContextState): { activeWord: number; activeLetter: number } => {
  const { solveState, activeLetter, activeWord, promptLetters, revealedLetters } = state;

  // if already on last word, return
  if (activeWord === solveState.length - 1) {
    return {
      activeWord,
      activeLetter,
    };
  }

  const newActiveWord = activeWord + 1;

  const firstAvailableLetter = solveState[newActiveWord]
    .split('')
    .findIndex(
      (_, idx) =>
        !promptLetters[newActiveWord].includes(idx) &&
        !revealedLetters[newActiveWord].includes(idx),
    );

  return {
    activeWord: newActiveWord,
    activeLetter: firstAvailableLetter,
  };
};

export const moveUp = (state: ContextState): { activeWord: number; activeLetter: number } => {
  const { solveState, activeLetter, activeWord, promptLetters, revealedLetters } = state;

  // if already on first word, return
  if (activeWord === 0) {
    return {
      activeWord,
      activeLetter,
    };
  }

  const newActiveWord = activeWord - 1;

  const firstAvailableLetter = solveState[newActiveWord]
    .split('')
    .findIndex(
      (_, idx) =>
        !promptLetters[newActiveWord].includes(idx) &&
        !revealedLetters[newActiveWord].includes(idx),
    );

  return {
    activeWord: newActiveWord,
    activeLetter: firstAvailableLetter,
  };
};

export const enterLetter = (
  letter: string,
  state: ContextState,
): { activeWord: number; activeLetter: number; solveState: string[] } => {
  const { solveState, activeLetter, activeWord, promptLetters, revealedLetters } = state;

  // don't override prompt/revealed letters
  if (
    promptLetters[activeWord].includes(activeLetter) ||
    revealedLetters[activeWord].includes(activeLetter)
  ) {
    return {
      activeWord,
      activeLetter,
      solveState,
    };
  }

  const newWord =
    solveState[activeWord].substring(0, activeLetter) +
    letter +
    solveState[activeWord].substring(activeLetter + 1);

  const { activeWord: newActiveWord, activeLetter: newActiveLetter } = moveForward(state);

  return {
    activeWord: newActiveWord,
    activeLetter: newActiveLetter,
    solveState: [...solveState.slice(0, activeWord), newWord, ...solveState.slice(activeWord + 1)],
  };
};

export const deleteLetter = (
  state: ContextState,
): { activeWord: number; activeLetter: number; solveState: string[] } => {
  const { solveState, activeLetter, activeWord, promptLetters, revealedLetters } = state;

  // don't override prompt/revealed letters
  if (
    promptLetters[activeWord].includes(activeLetter) ||
    revealedLetters[activeWord].includes(activeLetter)
  ) {
    return {
      activeWord,
      activeLetter,
      solveState,
    };
  }

  // deleting an empty square, should move backwards and delete that letter
  if (solveState[activeWord][activeLetter] === '*') {
    const { activeWord: newActiveWord, activeLetter: newActiveLetter } = moveBackward(state);
    const newWord =
      solveState[newActiveWord].substring(0, newActiveLetter) +
      '*' +
      solveState[newActiveWord].substring(newActiveLetter + 1);

    return {
      activeWord: newActiveWord,
      activeLetter: newActiveLetter,
      solveState: [
        ...solveState.slice(0, newActiveWord),
        newWord,
        ...solveState.slice(newActiveWord + 1),
      ],
    };
  } else {
    // deleting a filled square, delete letter and stay in that square
    const newWord =
      solveState[activeWord].substring(0, activeLetter) +
      '*' +
      solveState[activeWord].substring(activeLetter + 1);

    return {
      activeWord,
      activeLetter,
      solveState: [
        ...solveState.slice(0, activeWord),
        newWord,
        ...solveState.slice(activeWord + 1),
      ],
    };
  }
};
