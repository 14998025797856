import { Fragment, ReactNode } from 'react';
import { Dialog as HeadlessUiDialog, Transition } from '@headlessui/react';
import CloseIcon from '../assets/icons/close';
import classNames from 'classnames';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  fullScreen?: boolean;
  noXPadding?: boolean;
  children: ReactNode;
}

export default function Dialog({
  isOpen,
  onClose,
  title,
  fullScreen = false,
  noXPadding = false,
  children,
}: Props) {
  const wrapperClasses = classNames('min-height-100vh text-center', {
    'px-4': !fullScreen,
    'px-1': fullScreen,
  });

  const contentClasses = classNames(
    'sm:h-auto inline-flex flex-col w-full max-w-lg py-6 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-slate-700 shadow-xl rounded-2xl',
    {
      'my-8': !fullScreen,
      'my-1': fullScreen,
      'px-6': !noXPadding,
      'px-0': noXPadding,
      // can't use tailwind class because of mobile screen height bug, see useFullScreenHeight.tsx
      'height-100vh-with-padding': fullScreen,
      'overflow-hidden': !fullScreen,
      'overflow-scroll': fullScreen,
    },
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessUiDialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className={wrapperClasses}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessUiDialog.Overlay className="fixed inset-0 bg-black opacity-30 dark:opacity-70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block height-100vh align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={contentClasses}>
              <button className={`block ml-auto ${noXPadding ? 'pr-6' : 'pr-0'}`} onClick={onClose}>
                <CloseIcon className="fill-black dark:fill-white" />
              </button>
              {title && (
                <HeadlessUiDialog.Title
                  as="h3"
                  className="text-2xl text-center font-bold text-gray-900 dark:text-white"
                >
                  {title}
                </HeadlessUiDialog.Title>
              )}

              {children}
            </div>
          </Transition.Child>
        </div>
      </HeadlessUiDialog>
    </Transition>
  );
}
