import React from 'react';
import SettingsIcon from '../assets/icons/settings';
import StatsIcon from '../assets/icons/stats';
import InfoIcon from '../assets/icons/info';

interface Props {
  onRulesClick: () => void;
  onStatsClick: () => void;
  onSettingsClick: () => void;
}

export default function Header({ onRulesClick, onStatsClick, onSettingsClick }: Props) {
  return (
    <header className="border-b dark:border-b-slate-400 h-14 flex items-center px-2 sm:px-6 lg:px-8 dark:bg-slate-800 dark:text-white">
      <button onClick={onRulesClick} className="w-16" aria-label="How to Play">
        <InfoIcon className="fill-black dark:fill-white" />
      </button>

      <h1 className="flex-grow text-center text-3xl md:text-4xl font-bold font-heading uppercase pt-2">
        <span className="text-[34px] md:text-[42px]">R</span>
        hyme
        <span className="text-[34px] md:text-[42px]"> T</span>
        ime
      </h1>

      <div className="flex items-center w-16">
        <button onClick={onStatsClick} className="mr-4" aria-label="Stats">
          <StatsIcon className="fill-black dark:fill-white" />
        </button>
        <button onClick={onSettingsClick} aria-label="Settings">
          <SettingsIcon className="fill-black dark:fill-white" />
        </button>
      </div>
    </header>
  );
}
