import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import GameContextProvider from './state/GameContext';
import { AlertProvider } from './state/AlertContext';

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider>
      <GameContextProvider>
        <App />
      </GameContextProvider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
