import { useContext, useEffect, useState } from 'react';
import { Stats as IStats } from '../utils/localStorage';
import { add, startOfDay } from 'date-fns';
import Dialog from './Dialog';
import YesterdaySolution from './YesterdaySolution';
import { shareStatus } from '../utils/share';
import { useAlert } from '../state/AlertContext';
import { GameContext } from '../state/GameContext';

interface Props {
  stats: IStats;
  isSolved: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const Stat = ({ label, value }) => (
  <div className="text-center grow shrink basis-0 dark:text-white">
    <div className="font-medium text-2xl">{value}</div>
    <div className="text-sm">{label}</div>
  </div>
);

const formatSeconds = (seconds: number) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};

const calculateSecondsUntilTomorrow = () => {
  const tomorrow = Math.round(startOfDay(add(new Date(), { days: 1 })).getTime() / 1000);
  const now = Math.round(new Date().getTime() / 1000);
  return tomorrow - now;
};

export default function Stats({ stats, isSolved, isOpen, onClose }: Props) {
  const { showSuccess: showSuccessAlert } = useAlert();

  const gameState = useContext(GameContext);

  const [secondsUntilTomorrow, setSecondsUntilTomorrow] = useState(calculateSecondsUntilTomorrow());

  const { solves, currentStreak, maxStreak } = stats;

  const handleShareToClipboard = () => showSuccessAlert('Copied to clipboard!');

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsUntilTomorrow(calculateSecondsUntilTomorrow());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog title="Statistics" isOpen={isOpen} onClose={onClose}>
      <div className="mt-4 flex justify-center">
        <Stat label="Solves" value={solves} />
        <Stat label="Current Streak" value={currentStreak} />
        <Stat label="Max Streak" value={maxStreak} />
      </div>

      {isSolved && (
        <div className="mt-6 text-center flex justify-center">
          <div>
            <h4 className="text-lg text-center font-medium text-gray-900 dark:text-white">
              Next Rhyme Time:
            </h4>
            <p className="text-3xl text-center font-medium text-gray-900 dark:text-white">
              {formatSeconds(secondsUntilTomorrow)}
            </p>
          </div>

          <button
            className="border-2 border-blue-500 text-white bg-blue-500 rounded-xl ml-6 sm:ml-10 px-5 py-2 font-bold text-lg self-center"
            onClick={() => shareStatus(gameState, handleShareToClipboard)}
          >
            SHARE!
          </button>
        </div>
      )}

      <YesterdaySolution />
    </Dialog>
  );
}
