import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  width?: number;
  value?: string;
  disabled?: boolean;
  variant?: string;
  ariaLabel?: string;
  onClick: (value: string) => void;
}

export default function Key({
  children,
  width = 44,
  value,
  disabled,
  variant,
  ariaLabel,
  onClick,
}: Props) {
  const handleClick = (event) => {
    onClick(value);
    event.currentTarget.blur();
  };

  const className = classNames(
    'flex items-center justify-center rounded mx-0.5 text-sm font-bold cursor-pointer select-none',
    {
      'pointer-events-none bg-slate-100 dark:bg-slate-700 text-gray-500 dark:text-gray-400':
        disabled,
      'bg-slate-200 dark:bg-slate-500 dark:text-gray-100 hover:bg-slate-300 active:bg-slate-400 dark:hover:bg-slate-400':
        !disabled && variant !== 'action',
      'bg-blue-500 text-white hover:bg-blue-400 active:bg-blue-400':
        !disabled && variant === 'action',
    },
  );

  return (
    <button
      style={{ width: `${width}px`, height: '60px' }}
      className={className}
      onClick={handleClick}
      aria-label={ariaLabel || value}
    >
      {children || value.toUpperCase()}
    </button>
  );
}
