import { useEffect } from 'react';

export default function useDarkMode(isDarkMode) {
  // tailwind dark mode is enabled when 'dark' class is added to html
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);
}
