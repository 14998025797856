import { Switch } from '@headlessui/react';
import Dialog from './Dialog';

interface Props {
  isDarkMode: boolean;
  handleDarkMode: (isDarkMode: boolean) => void;
  isOpen: boolean;
  onClose: () => void;
}

interface SettingSwitchProps {
  label: string;
  enabled: boolean;
  onChange: (checked: boolean) => void;
}

const SettingSwitch = ({ label, enabled, onChange }: SettingSwitchProps) => (
  <div className="flex items-center justify-between py-4">
    <Switch.Group>
      <Switch.Label className="text-lg font-medium dark:text-white">{label}</Switch.Label>
      <Switch
        checked={enabled}
        onChange={onChange}
        className={`${enabled ? 'bg-blue-500' : 'bg-gray-700'}
      relative inline-flex flex-shrink-0 h-[22px] w-[46px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span
          className={`${
            enabled ? 'translate-x-6' : 'translate-x-0'
          } inline-block w-[18px] h-[18px] transform bg-white rounded-full transition-transform`}
        />
      </Switch>
    </Switch.Group>
  </div>
);

export default function Settings({ isDarkMode, handleDarkMode, isOpen, onClose }: Props) {
  return (
    <Dialog title="Settings" isOpen={isOpen} onClose={onClose}>
      <div className="mt-4">
        <SettingSwitch enabled={isDarkMode} onChange={handleDarkMode} label="Dark Theme" />

        <div className="border-t flex items-center justify-between py-4">
          <p className="text-lg font-medium dark:text-white">Feedback</p>
          <a href="mailto:rhymetimegame@gmail.com?subject=Rhyme%20Time%20Feedback" className='text-gray-700 dark:text-gray-100 underline'>Email</a>
        </div>

        <p className='text-gray-500 dark:text-white text-xs mt-8'>&copy; {new Date().getFullYear()} Rhyme Time</p>
      </div>
    </Dialog>
  );
}
