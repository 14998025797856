import React from 'react';
import Dialog from './Dialog';

const Link = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className="text-blue-500 dark:text-blue-300 underline"
  >
    {children}
  </a>
);

export default function Rules({ isOpen, onClose }) {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="How to Play" fullScreen>
      <div className="mt-6 flex flex-col h-full">
        <p className="text-md text-center font-medium dark:text-white">
          These three words rhyme. Can you figure out what they are?
        </p>
        <p className="mt-6 text-sm font-medium dark:text-white">
          Fill in all three words and hit enter to submit your guess. There may be more than one
          valid solution for each prompt. You have unlimited guesses to figure out a rhyming set.
        </p>
        <p className="mt-6 text-sm font-medium dark:text-white">
          Stuck? Tap on the alphabet icon to guess a letter. We'll reveal if that letter
          appears anywhere within the solution. You only get one letter guess per day, so choose
          wisely!
        </p>
        <p className="mt-6 text-sm font-medium dark:text-white">
          Want more? Come back tomorrow for another Rhyme Time!
        </p>
        <p className="mt-auto pt-6 text-[10px] sm:text-xs font-medium text-gray-700 dark:text-gray-200">
          Note: Shout out to <Link href="https://www.decontextualize.com/">Allison Parrish</Link>{' '}
          for her awesome{' '}
          <Link href="https://github.com/aparrish/pronouncingjs">open-source code</Link> that finds
          rhymes. Solutions are determined using the{' '}
          <Link href="https://en.wikipedia.org/wiki/CMU_Pronouncing_Dictionary">
            CMU Pronouncing Dictionary
          </Link>
          , which has a few quirks. Apologies if a rhyme is missing!
        </p>
      </div>
    </Dialog>
  );
}
