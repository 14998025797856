import { useEffect } from 'react';

export default function useTabFocus() {
  // add outline focus class to body on first tab
  useEffect(() => {
    const handleFirstTab = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        document.body.classList.add('user-is-tabbing');
      }
    };
    document.addEventListener('keydown', handleFirstTab);
    return () => document.removeEventListener('keydown', handleFirstTab);
  }, []);
}
