import { useContext, useEffect, useState } from 'react';
import Keyboard from './components/Keyboard';
import Word from './components/Word';
import useKeydownHandlers from './hooks/useKeydownHandlers';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import { allPromptsFilled, doWordsRhyme } from './utils/promptFunctions';
import { GameContext } from './state/GameContext';
import { deleteLetter, enterLetter } from './utils/navigation';
import { AlertContainer } from './components/AlertContainer';
import { useAlert } from './state/AlertContext';
import {
  loadStats,
  saveRevealedLetterToLocalStorage,
  saveSolutionToLocalStorage,
  updateStatsAfterCompletedGame,
} from './utils/localStorage';
import Header from './components/Header';
import Stats from './components/Stats';
import Settings from './components/Settings';
import Rules from './components/Rules';
import LetterRevealTrigger from './components/LetterRevealTrigger';
import Confetti from 'react-confetti';
import ReactGa from 'react-ga4';
import useTabFocus from './hooks/useTabFocus';
import useDarkMode from './hooks/useDarkMode';
import useFullScreenHeight from './hooks/useFullScreenHeight';
import LetterRevealDialog from './components/LetterRevealDialog';

function App() {
  const gameState = useContext(GameContext);
  const {
    solveState,
    promptLetters,
    activeWord,
    activeLetter,
    revealedLetter,
    revealedLetters,
    isSolved,
    setActiveLetter,
    setRevealedLetter,
    setActiveWord,
    setSolveState,
    setIsSolved,
  } = gameState;

  const { showError: showErrorAlert } = useAlert();

  const [stats, setStats] = useState(() => loadStats());
  const [statsOpen, setStatsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [rulesOpen, setRulesOpen] = useState(false);
  const [letterRevealOpen, setLetterRevealOpen] = useState(false);
  const [isRevealing, setIsRevealing] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [isLetterRevealing, setIsLetterRevealing] = useState(false);
  const [isLetterRevealed, setIsLetterRevealed] = useState(false);
  const [confettiFalling, setConfettiFalling] = useState(false);
  const [showRevealedLetterAlert, setShowRevealedLetterAlert] = useState(false);

  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme') ? localStorage.getItem('theme') === 'dark' : prefersDarkMode,
  );

  // used for grid animations
  const [gridClass, setGridClass] = useState('');

  // show rules dialog after 1sec on first site visit
  useEffect(() => {
    const onboardingTimeout = setTimeout(() => {
      if (!localStorage.getItem('onboarded')) {
        setRulesOpen(true);
        localStorage.setItem('onboarded', '1');
      }
    }, 500);

    const statsTimeout = setTimeout(() => {
      // open stats on load if today's puzzle is already solved
      if (isSolved) {
        setStatsOpen(true);
      }
    }, 2700);

    return () => {
      clearTimeout(onboardingTimeout);
      clearTimeout(statsTimeout);
    };
    // only want initial value of isSolved
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // flip already-revealed letters on load
    if (revealedLetter && !isLetterRevealed && !isLetterRevealing) {
      setIsLetterRevealed(true);
    }

    // flip over letters when puzzle is solved
    if (isSolved && !isRevealing && !isRevealed) {
      const timeout = setTimeout(() => setIsRevealing(true), 250);

      return () => clearTimeout(timeout);
    }

    // stop flip animation
    if (isRevealing && !isRevealed) {
      const timeout = setTimeout(() => {
        setIsRevealed(true);
        setIsRevealing(false);
      }, 2500);

      return () => clearTimeout(timeout);
    }

    // stop flip animation
    if (isLetterRevealing && !isLetterRevealed) {
      const timeout = setTimeout(() => {
        setIsLetterRevealed(true);
        setIsLetterRevealing(false);
      }, 2500);

      return () => clearTimeout(timeout);
    }
  }, [isSolved, isRevealing, isRevealed, isLetterRevealing, isLetterRevealed, revealedLetter]);

  useEffect(() => {
    if (
      showRevealedLetterAlert &&
      revealedLetter &&
      revealedLetters.every((word) => word.length === 0)
    ) {
      setGridClass('jiggle');
      showErrorAlert(`Sorry, not a single ${revealedLetter.toUpperCase()}!`, {
        onClose: () => setGridClass(''),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revealedLetter, JSON.stringify(revealedLetters), showRevealedLetterAlert]);

  const handleLetter = (letter: string) => {
    if (isSolved || letterRevealOpen) return;

    const {
      activeWord: newActiveWord,
      activeLetter: newActiveLetter,
      solveState: newSolveState,
    } = enterLetter(letter, gameState);

    setActiveWord(newActiveWord);
    setActiveLetter(newActiveLetter);
    setSolveState(newSolveState);
  };

  const handleDelete = () => {
    if (isSolved || letterRevealOpen) return;

    const {
      activeWord: newActiveWord,
      activeLetter: newActiveLetter,
      solveState: newSolveState,
    } = deleteLetter(gameState);

    setActiveWord(newActiveWord);
    setActiveLetter(newActiveLetter);
    setSolveState(newSolveState);
  };

  const handleEnter = () => {
    if (isSolved || letterRevealOpen || !allPromptsFilled(solveState)) return;

    // prevent opening dialogs whose trigger buttons are in focus
    (document.activeElement as HTMLElement).blur();

    if (doWordsRhyme(solveState)) {
      setIsSolved(true);
      setStats(updateStatsAfterCompletedGame(stats));
      saveSolutionToLocalStorage(solveState);
      setConfettiFalling(true);
      ReactGa.event({
        category: 'Game',
        action: 'Solved',
        label: solveState.join(','),
      });
    } else {
      setGridClass('jiggle');
      showErrorAlert('Not quite, keep trying!', {
        onClose: () => setGridClass(''),
      });
    }
  };

  const handleLetterReveal = (letter: string) => {
    saveRevealedLetterToLocalStorage(letter);
    setLetterRevealOpen(false);
    ReactGa.event({
      category: 'Game',
      action: 'Revealed',
      label: letter,
    });

    // only show alert right after unsuccessful letter reveal, not on subsequent loads
    setShowRevealedLetterAlert(true);
    setTimeout(() => {
      setIsLetterRevealing(true);
      setRevealedLetter(letter);
    }, 500);
  };

  useKeydownHandlers({ handleDelete, handleEnter, handleLetter });
  useGoogleAnalytics();
  useTabFocus();
  useDarkMode(isDarkMode);
  useFullScreenHeight();

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark);
    localStorage.setItem('theme', isDark ? 'dark' : 'light');
  };

  return (
    <div className="app">
      <Header
        onStatsClick={() => setStatsOpen(true)}
        onRulesClick={() => setRulesOpen(true)}
        onSettingsClick={() => setSettingsOpen(true)}
      />

      <main className="py-8 mx-auto px-2 sm:px-6 lg:px-8 flex flex-col h-[calc(100%-56px)] dark:bg-slate-800">
        <div className="mb-2 flex-grow flex items-center justify-center">
          <div>
            {solveState.map((word, idx) => (
              <Word
                key={idx}
                word={word}
                wordIdx={idx}
                activeLetter={activeWord === idx ? activeLetter : null}
                promptLetters={promptLetters[idx]}
                revealedLetters={revealedLetters[idx]}
                isRevealing={isRevealing}
                isRevealed={isRevealed}
                isLetterRevealing={isLetterRevealing}
                isLetterRevealed={isLetterRevealed}
                extraClassName={gridClass}
              />
            ))}
          </div>
        </div>

        <LetterRevealTrigger
          isDisabled={!!revealedLetter || isSolved}
          onClick={() => setLetterRevealOpen(true)}
        />

        <Keyboard onChar={handleLetter} onDelete={handleDelete} onEnter={handleEnter} />

        <AlertContainer />

        <Stats
          stats={stats}
          isSolved={isSolved}
          isOpen={statsOpen}
          onClose={() => setStatsOpen(false)}
        />

        <Settings
          isDarkMode={isDarkMode}
          handleDarkMode={handleDarkMode}
          isOpen={settingsOpen}
          onClose={() => setSettingsOpen(false)}
        />

        <Rules isOpen={rulesOpen} onClose={() => setRulesOpen(false)} />

        <LetterRevealDialog
          isOpen={letterRevealOpen}
          onClose={() => setLetterRevealOpen(false)}
          onEnter={handleLetterReveal}
        />

        {confettiFalling && (
          <Confetti
            gravity={0.15}
            recycle={false}
            onConfettiComplete={() => {
              setConfettiFalling(false);
              setStatsOpen(true);
            }}
          />
        )}
      </main>
    </div>
  );
}

export default App;
