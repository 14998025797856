import React, { useContext } from 'react';
import { allPromptsFilled } from '../utils/promptFunctions';
import { GameContext } from '../state/GameContext';
import Key from './Key';
import DeleteIcon from '../assets/icons/delete';

interface Props {
  onChar: (value: string) => void;
  onDelete: () => void;
  onEnter: () => void;
  noEnterButton?: boolean;
}

const ROW_1 = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'];
const ROW_2 = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'];
const ROW_3 = ['z', 'x', 'c', 'v', 'b', 'n', 'm'];

const Spacer = () => <div className="w-[22px]" />;

export default function Keyboard({ onChar, onDelete, onEnter, noEnterButton }: Props) {
  const { solveState, isSolved } = useContext(GameContext);

  return (
    <div>
      <div className="flex justify-center mb-1">
        {ROW_1.map((value) => (
          <Key key={value} value={value} onClick={onChar} />
        ))}
      </div>
      <div className="flex justify-center mb-1">
        <Spacer />
        {ROW_2.map((value) => (
          <Key key={value} value={value} onClick={onChar} />
        ))}
        <Spacer />
      </div>
      <div className="flex justify-center">
        <Key
          width={68}
          value={noEnterButton ? '' : 'ENTER'}
          onClick={onEnter}
          disabled={noEnterButton || !allPromptsFilled(solveState)}
          variant={!isSolved && 'action'}
        />
        {ROW_3.map((value) => (
          <Key key={value} value={value} onClick={onChar} />
        ))}
        <Key width={68} onClick={onDelete} ariaLabel="Delete">
          <DeleteIcon className="fill-black dark:fill-white" />
        </Key>
      </div>
    </div>
  );
}
