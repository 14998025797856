import { format, startOfDay, parse, differenceInDays } from 'date-fns';

const STATS_KEY = 'stats';
const SOLUTION_KEY = 'solution';
const LETTER_REVEAL_KEY = 'letterReveal';
const DATE_FORMAT = 'yyyy-MM-dd';

export interface Stats {
  solves: number;
  currentStreak: number;
  maxStreak: number;
  lastSolveDate: string | null;
}

const daysSince = (pastDay: string) =>
  differenceInDays(startOfDay(new Date()), parse(pastDay, DATE_FORMAT, new Date()));

export const saveSolutionToLocalStorage = (solution: string[] | null) => {
  if (solution === null) {
    localStorage.removeItem(SOLUTION_KEY);
  } else {
    localStorage.setItem(SOLUTION_KEY, JSON.stringify(solution));
  }
};

export const loadSolutionFromLocalStorage = (): string[] | null => {
  const solution = localStorage.getItem(SOLUTION_KEY);

  if (!solution) return null;

  // only use stored solution if it is today's already-solved solution
  const stats = loadStats();
  const isTodaySolved = stats.lastSolveDate !== null && daysSince(stats.lastSolveDate) === 0;
  if (!isTodaySolved) {
    saveSolutionToLocalStorage(null);
    return null;
  }

  return JSON.parse(solution) as string[];
};

export const saveRevealedLetterToLocalStorage = (letterReveal: string | null) => {
  if (letterReveal === null) {
    localStorage.removeItem(LETTER_REVEAL_KEY);
  } else {
    const letterRevealData = {
      date: format(new Date(), DATE_FORMAT),
      letter: letterReveal,
    };
    localStorage.setItem(LETTER_REVEAL_KEY, JSON.stringify(letterRevealData));
  }
};

export const loadRevealedLetterFromLocalStorage = (): string | null => {
  const letterRevealData = localStorage.getItem(LETTER_REVEAL_KEY);

  if (!letterRevealData) return null;

  const { date, letter } = JSON.parse(letterRevealData);

  // if stored letter is from a previous day, reset letter reveal to null
  if (daysSince(date) !== 0) {
    saveRevealedLetterToLocalStorage(null);
    return null;
  }

  return letter;
};

const saveStatsToLocalStorage = (stats: Stats) => {
  localStorage.setItem(STATS_KEY, JSON.stringify(stats));
};

const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(STATS_KEY);
  return stats ? (JSON.parse(stats) as Stats) : null;
};

const defaultStats: Stats = {
  solves: 0,
  currentStreak: 0,
  maxStreak: 0,
  lastSolveDate: null,
};

export const updateStatsAfterCompletedGame = (existingStats: Stats): Stats => {
  const updatedStats = {
    solves: existingStats.solves + 1,
    currentStreak: existingStats.currentStreak + 1,
    maxStreak: Math.max(existingStats.maxStreak, existingStats.currentStreak + 1),
    lastSolveDate: format(new Date(), DATE_FORMAT),
  };

  saveStatsToLocalStorage(updatedStats);

  return updatedStats;
};

const updateStatsOnLoad = (existingStats: Stats): Stats => {
  const updatedStats = {
    ...existingStats,
    // if yesterday wasn't most recent solve, reset streak to 0
    currentStreak:
      existingStats.lastSolveDate === null || daysSince(existingStats.lastSolveDate) > 1
        ? 0
        : existingStats.currentStreak,
  };

  saveStatsToLocalStorage(updatedStats);

  return updatedStats;
};

export const loadStats = () => {
  const storedStats = loadStatsFromLocalStorage() || defaultStats;
  return updateStatsOnLoad(storedStats);
};
