import React from 'react';
import classnames from 'classnames';

interface Props {
  isDisabled: boolean;
  onClick: () => void;
}

export default function LetterRevealTrigger({ isDisabled, onClick }: Props) {
  const btnClasses = classnames(
    'h-11 w-11 md:h-12 md:w-12 flex items-center justify-center text-sm md:text-base font-bold rounded-full border-2',
    {
      'text-blue-500 border-blue-500 dark:text-blue-400 dark:bg-slate-700 dark:border-blue-400':
        !isDisabled,
      'bg-white text-gray-400 border-gray-400 dark:bg-slate-800 dark:text-gray-500 dark:border-gray-500 pointer-events-none':
        isDisabled,
    },
  );

  return (
    <div className="flex justify-end mb-6">
      <button className={btnClasses} onClick={onClick}>
        ABC
      </button>
    </div>
  );
}
