import { differenceInDays, parse, startOfDay } from 'date-fns';
import PUZZLES, { Puzzle } from '../data/puzzles';
import words from '../data/words.json';

export const doWordsRhyme = (words: string[]) => {
  const rhymingWords = window.pronouncing.rhymes(words[0], true);
  return words.slice(1).every((word) => rhymingWords.includes(word));
};

export const allPromptsFilled = (words: string[]) => words.every((word) => !word.includes('*'));

// find all solutions for a set of prompts
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const findRhymingCombos = (prompts: string[]) => {
  const output = [];

  // map each prompt to an array of every word that fits that prompt
  const matchingWords = prompts.map((prompt) =>
    words.filter(
      (word) =>
        word.length === prompt.length &&
        prompt.split('').every((letter, i) => letter === '*' || letter === word[i]),
    ),
  );

  // find the prompt that has the fewest matching words
  const fewestMatchesIndex = matchingWords.findIndex(
    (matches) => matches.length === Math.min(...matchingWords.map((m) => m.length)),
  );

  const fewestMatches = matchingWords[fewestMatchesIndex];

  const otherPrompts = [
    ...matchingWords.slice(0, fewestMatchesIndex),
    ...matchingWords.slice(fewestMatchesIndex + 1),
  ];

  // for each match, find words for other prompts that rhyme
  fewestMatches.forEach((word) => {
    const rhymes = window.pronouncing.rhymes(word);

    const otherPromptRhymes = otherPrompts.map((otherPrompt) =>
      otherPrompt.filter((promptMatch) => rhymes.includes(promptMatch)),
    );

    // continue on if any prompt has no rhymes that fit
    if (otherPromptRhymes.some((r) => !r.length)) return;

    output.push([[word], ...otherPromptRhymes]);
  });

  return output;
};

export const getTodaysPuzzleIndex = (): number => {
  return differenceInDays(startOfDay(new Date()), parse('2022-04-25', 'yyyy-MM-dd', new Date()));
};

export const getTodaysPuzzle = (): Puzzle => {
  const index = getTodaysPuzzleIndex();
  return PUZZLES[index % PUZZLES.length];
};

export const getYesterdaysPuzzle = (): Puzzle | null => {
  const index = getTodaysPuzzleIndex() - 1;
  return index >= 0 ? PUZZLES[index % PUZZLES.length] : null;
};
