import { useEffect } from 'react';
import ReactGa from 'react-ga4';

const gaId = process.env.REACT_APP_GA_ID || 'G-4LB3HX0FF1';

export default function useGoogleAnalytics() {
  useEffect(() => {
    ReactGa.initialize(gaId, {
      gaOptions: { cookieFlags: 'samesite=none;secure' },
    });

    ReactGa.event({
      category: 'Game',
      action: 'Viewed',
    });
  }, []);
}
