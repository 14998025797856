import { useEffect } from 'react';

export default function useFullScreenHeight() {
  useEffect(() => {
    const handleResize = () => {
      // Get the viewport height and multiply it by 1% to get a value for a vh unit
      let vh = document.documentElement.clientHeight * 0.01;
      // Set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
}
