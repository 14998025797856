import classNames from 'classnames';
import { useState } from 'react';
import useKeydownHandlers from '../hooks/useKeydownHandlers';
import Dialog from './Dialog';
import Keyboard from './Keyboard';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onEnter: (letter: string) => void;
}

export default function LetterRevealDialog({ isOpen, onClose, onEnter }: Props) {
  const [revealLetter, setRevealLetter] = useState('');

  const revealButtonClasses = classNames(
    'mt-4 py-1 px-4 rounded-3xl text-lg border-2 uppercase font-bold',
    {
      'pointer-events-none': revealLetter === '',
      'border-blue-500 text-blue-500 dark:border-blue-400 dark:text-blue-400': revealLetter !== '',
      'border-gray-300 text-gray-300 dark:border-gray-500 dark:text-gray-500': revealLetter === '',
    },
  );

  const handleLetter = (letter: string) => {
    if (isOpen) setRevealLetter(letter);
  };

  const handleDelete = () => {
    if (isOpen) setRevealLetter('');
  };

  const handleEnter = () => {
    if (isOpen && revealLetter) onEnter(revealLetter);
  };

  useKeydownHandlers({ handleLetter, handleEnter, handleDelete });

  return (
    <Dialog isOpen={isOpen} onClose={onClose} fullScreen noXPadding>
      <div className="flex flex-col mt-4 h-full">
        <div className="flex flex-col flex-grow px-6 mb-4 items-center">
          <p className="dark:text-white text-center">
            <b>Choose one letter</b> and we'll show you everywhere that letter appears in today's
            puzzle.
          </p>

          <p className="dark:text-white mt-4 text-center">
            You only get one reveal per day, so choose wisely!
          </p>

          <div className="flex flex-col flex-grow items-center justify-center sm:py-16">
            <div className="py-2 h-12 w-32 bg-gray-200 dark:bg-gray-500 dark:text-white rounded-3xl font-bold text-2xl uppercase text-center flex items-center justify-center">
              {revealLetter}
            </div>
            <button className={revealButtonClasses} onClick={() => onEnter(revealLetter)}>
              Reveal!
            </button>
          </div>
        </div>
        <div className=''>
          <Keyboard
            onChar={handleLetter}
            onDelete={handleDelete}
            onEnter={handleEnter}
            noEnterButton={true}
          />
        </div>
      </div>
    </Dialog>
  );
}
