import classnames from 'classnames';
import React, { useContext } from 'react';
import { GameContext } from '../state/GameContext';

interface WordProps {
  word: string;
  wordIdx: number;
  activeLetter: number;
  promptLetters: number[];
  revealedLetters: number[];
  isRevealing: boolean;
  isRevealed: boolean;
  isLetterRevealing: boolean;
  isLetterRevealed: boolean;
  extraClassName: string;
}

export default function Word({
  word,
  wordIdx,
  activeLetter,
  promptLetters,
  revealedLetters,
  isRevealing,
  isRevealed,
  isLetterRevealing,
  isLetterRevealed,
  extraClassName,
}: WordProps) {
  const { setActiveLetter, setActiveWord, solveState } = useContext(GameContext);

  const maxWordLength = Math.max(...solveState.map((word) => word.length));

  const handleLetterClick = (letterIdx) => {
    setActiveWord(wordIdx);
    setActiveLetter(letterIdx);
  };

  const getCellClasses = (idx: number) =>
    classnames(
      'flex justify-center items-center mx-1 p-2 md:mx-2 rounded border-2 dark:border-slate-600 font-bold text-xl sm:text-2xl md:text-3xl',
      {
        'h-10 w-10 xs:h-12 xs:w-12 sm:h-14 sm:w-14 md:h-20 md:w-20': maxWordLength < 8,
        'h-8 w-8 xs:h-10 xs:w-10 sm:h-14 sm:w-14 md:h-18 md:w-18': maxWordLength >= 8,
        'bg-blue-100 dark:bg-slate-700': activeLetter === idx,
        'dark:bg-slate-900': activeLetter !== idx,
        'text-gray-500 dark:text-gray-400': promptLetters.includes(idx),
        'text-black dark:text-white': !promptLetters.includes(idx),
        'pointer-events-none': promptLetters.includes(idx) || revealedLetters.includes(idx),
        'cursor-pointer':
          !isRevealing &&
          !isRevealed &&
          !promptLetters.includes(idx) &&
          !revealedLetters.includes(idx),
        'cell-reveal': isRevealing,
        'cell-revealed': isRevealed,
        'letter-reveal': isLetterRevealing && revealedLetters.includes(idx),
        'letter-revealed': isLetterRevealed && revealedLetters.includes(idx),
      },
    );

  const animationDelay = isRevealing ? `${wordIdx * 800 + 350}ms` : '0ms';

  return (
    <div className={`flex justify-center items-center mb-6 ${extraClassName}`}>
      {word.split('').map((letter, idx) => (
        <div
          key={idx}
          className={getCellClasses(idx)}
          onClick={() => handleLetterClick(idx)}
          style={{ animationDelay }}
        >
          <div className="letter-container" style={{ animationDelay }}>
            {letter === '*' ? '' : letter.toUpperCase()}
          </div>
        </div>
      ))}
    </div>
  );
}
